@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400&display=swap');
@import url("./assets/css/backgrounds.css");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: 'Space Grotesk', sans-serif;
  }
}

.bg-building{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('./assets/images/derived/1920x1080/ed-robertson.jpg');
}

.bg-swirl-4 {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('./assets/images/derived/1920x1080/pawel-czerwinski.jpg');
}

.bg-swirl {
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/derived/1920x1080/swirl-1.jpg");
  background-size: cover;
}

.swirls {
  width: 100%;
  height: 100%;
  position:relative;
  z-index:0;
}

.swirls::before {
  content:"";
  position:absolute;
  z-index:-1;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-size: cover;
  transition: all 1s;
}

/*see: src/misc/rectanglegen.py*/
.swirls-0::before {
  background-image: url("./assets/images/derived/1920x1080/swirl-0.jpg");
  clip-path: polygon(
    calc(var(--grid-size)*40+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*40+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*36+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*36+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*30+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*30+var(--grid-circle-radius)) calc(var(--grid-size)*12+var(--grid-circle-radius)),
    calc(var(--grid-size)*16+var(--grid-circle-radius)) calc(var(--grid-size)*12+var(--grid-circle-radius)),
    calc(var(--grid-size)*16+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
    calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
    calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius))
  );
}

.swirls-1::before {
  background-image: url("./assets/images/derived/1920x1080/andrei-caliman.jpg");
  clip-path: polygon(
    calc(var(--grid-size)*40+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*40+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*25+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*25+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*20+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*20+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*20+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
    calc(var(--grid-size)*15+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
    calc(var(--grid-size)*15+var(--grid-circle-radius)) calc(var(--grid-size)*13+var(--grid-circle-radius)),
    calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*13+var(--grid-circle-radius)),
    calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius)),
    calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*5+var(--grid-circle-radius))
  );
}

.swirls-2::before {
  background-image: url("./assets/images/derived/1920x1080/swirl-2.jpg");
  clip-path: polygon(
    calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
    calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
    calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
    calc(var(--grid-size)*19+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
    calc(var(--grid-size)*19+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*32+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*32+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
    calc(var(--grid-size)*36+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
    calc(var(--grid-size)*36+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*41+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
    calc(var(--grid-size)*41+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))
  );
}

.swirls-3::before {
  background-image: url("./assets/images/derived/1920x1080/abel-perez.jpg");
  clip-path: polygon(
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*30+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
    calc(var(--grid-size)*30+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*18+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*18+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius))
  );
}

.swirls-4::before {
  background-image: url("./assets/images/derived/1920x1080/abel-perez.jpg");
  clip-path: polygon(
    calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*4+var(--grid-circle-radius)),
    calc(var(--grid-size)*6+var(--grid-circle-radius)) calc(var(--grid-size)*4+var(--grid-circle-radius)),
    calc(var(--grid-size)*6+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*14+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
    calc(var(--grid-size)*14+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*22+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
    calc(var(--grid-size)*26+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
    calc(var(--grid-size)*26+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*26+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*26+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius)),
    calc(var(--grid-size)*26+var(--grid-circle-radius)) calc(var(--grid-size)*7+var(--grid-circle-radius))
  );
}

@media screen and (max-width: 640px) {
  .swirls-0::before {
    background-image: url("./assets/images/derived/1920x1080/swirl-0.jpg");
    clip-path: polygon(
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*5+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))
    );
  }
  
  .swirls-1::before {
    background-image: url("./assets/images/derived/1920x1080/andrei-caliman.jpg");
    clip-path: polygon(
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))

    );
  }
  
  .swirls-2::before {
    background-image: url("./assets/images/derived/1920x1080/swirl-2.jpg");
    clip-path: polygon(
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*2+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*3+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*8+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))
    );
  }
  
  .swirls-3::before {
    background-image: url("./assets/images/derived/1920x1080/abel-perez.jpg");
    clip-path: polygon(
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*9+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*9+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*4+var(--grid-circle-radius)) calc(var(--grid-size)*9+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*9+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*9+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*10+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*3+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*11+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))
    );
  }
  
  .swirls-4::before {
    background-image: url("./assets/images/derived/1920x1080/abel-perez.jpg");
    clip-path: polygon(
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*0+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*11+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*8+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*2+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*7+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*0+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*1+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius)),
      calc(var(--grid-size)*10+var(--grid-circle-radius)) calc(var(--grid-size)*6+var(--grid-circle-radius))
    );
  }
}

.headerBanner {
  height: calc(var(--grid-size)*12);
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
  opacity: 1;
    transition:all 1s ease-out;
}

.page-exit {
    transform: translateX(0);
}

.page-exit-active {
    transform: translateX(-100%);
    transition:all 1s ease;
}      

.header-transition-enter {
  opacity: 0;
  transform: scale(1.1);
}

.header-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.header-transition-exit {
  opacity: 1;
  transform: scale(1);
}

.header-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.list-dash {
  list-style-type: none;
}

.list-dash li {
  padding-left: 1em;
}
.list-dash li:before {
  content: '-';
  position: absolute;
  margin-left: -20px;
}
