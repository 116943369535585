:root {
  --grid-size: 44px;
  --background-offset: 25px;
  --grid-circle-radius: 3px;
  --grid-circle-radius-alias: calc(var(--grid-circle-radius)+-1px);
  --grid-circle-diameter: calc(var(--grid-circle-radius)*2);
}

.dot-grid-grey {        
  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 50% 50%, rgba(104, 113, 120, 1) 0%, rgba(104, 113, 120, 1) var(--grid-circle-radius-alias), rgba(104, 113, 120, 0) var(--grid-circle-radius), rgba(104, 113, 120, 0) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 50% 50%, rgba(104, 113, 120, 1) 0%, rgba(104, 113, 120, 1) var(--grid-circle-radius-alias), rgba(104, 113, 120, 0) var(--grid-circle-radius), rgba(104, 113, 120, 0) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 50% 50%, rgba(104, 113, 120, 1) 0%, rgba(104, 113, 120, 1) var(--grid-circle-radius-alias), rgba(104, 113, 120, 0) var(--grid-circle-radius), rgba(104, 113, 120, 0) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 50% 50%, rgba(104, 113, 120, 1) 0%, rgba(104, 113, 120, 1) var(--grid-circle-radius-alias), rgba(104, 113, 120, 0) var(--grid-circle-radius), rgba(104, 113, 120, 0) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 50% 50%, rgba(104, 113, 120, 1) 0%, rgba(104, 113, 120, 1) var(--grid-circle-radius-alias), rgba(104, 113, 120, 0) var(--grid-circle-radius), rgba(104, 113, 120, 0) 100%);
          
  background-position: var(--background-offset) var(--background-offset);
  background-size: var(--grid-size) var(--grid-size);
  height: cover;
  width: 100%;
}
